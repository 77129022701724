import { FC } from 'react';
import { usePageStatisticsQuery } from 'app/modules/insights/modules/postsPerformance/hooks/usePageStatisticsQuery';
import SimpleGrid from 'app/components/SimpleGrid';
import StatisticCard from 'app/modules/insights/modules/postsPerformance/components/pageStatistics/StatisticCard';
import { getPageStatisticsCards } from 'app/modules/insights/modules/postsPerformance/utils/getPageStatisticsCards';
import { Page } from 'app/modules/insights/types/Page';
import { Dayjs } from 'dayjs';
import { Alert } from '@kontentino/ui';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
};

const PageOverviewExportStats: FC<Props> = ({ ...props }) => {
  const { error, data, loading } = usePageStatisticsQuery(props);

  return (
    <div data-cy="insights-posts-performance-statistics">
      {error && (
        <Alert
          title="Unable to fetch statistics"
          variant="danger"
          className="tw-mb-3"
        />
      )}
      {data &&
        !loading &&
        ((cards: ReturnType<typeof getPageStatisticsCards>) => (
          <div className="tw-mb-4">
            <SimpleGrid gap={16} columns={2}>
              {cards.visible
                .filter((card) => card.value !== null)
                .map((card) => (
                  <StatisticCard
                    key={card.key}
                    name={card.key}
                    value={card.value}
                    title={card.title}
                    color={card.color}
                    icon={card.icon}
                    info={card.info}
                    isHighlighted={false}
                    className="tw-border tw-border-grayscale-20"
                    withExactValue
                  />
                ))}
            </SimpleGrid>
          </div>
        ))(
          getPageStatisticsCards(data, {
            maxLength: data.metrics.length,
          }),
        )}
    </div>
  );
};

export default PageOverviewExportStats;

import { Editor } from '@tiptap/core';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Field,
  Icon,
  IconButton,
  mergeTailwindClasses,
  Toggle,
  Tooltip,
} from '@kontentino/ui';
import { faIndent } from '@fortawesome/pro-regular-svg-icons/faIndent';
import { faPenSwirl } from '@fortawesome/pro-regular-svg-icons/faPenSwirl';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { faSpellCheck } from '@fortawesome/pro-regular-svg-icons/faSpellCheck';
import { useTextEditorAiContent } from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import { TextTransformAction } from 'app/modules/posts/api/GenerationsApi';
import clsx from 'clsx';
import { faBolt } from '@fortawesome/pro-regular-svg-icons/faBolt';
import { faFaceSmile } from '@fortawesome/pro-regular-svg-icons/faFaceSmile';
import { faArrowRightToArc } from '@fortawesome/pro-regular-svg-icons/faArrowRightToArc';
import LoadingGeneratedResults from 'app/modules/aiContent/components/results/LoadingGeneratedResults';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import TextEditorAiContentRefineTextSelectionInput from 'app/modules/textEditor/components/textEditorAiContent/textEditorAiContentRefineTextSelection/TextEditorAiContentRefineTextSelectionInput';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faCircleQuestion';
import useBoolean from 'utils/hooks/useBoolean';

type Props = {
  editor: Editor;
};

const parseOptions = {
  preserveWhitespace: true,
} as const;

const TextEditorAiContentRefineTextSelection: FC<Props> = ({
  editor,
}: Props) => {
  const { t } = useTranslation();
  const {
    refineTextSelectionVisible,
    queries: { transformText },
  } = useTextEditorAiContent();
  const [visible, setVisible] = useBoolean(false);
  const [lastStyleUsed, setLastStyleUsed] = useState<
    TextTransformAction | undefined
  >();
  const [lastCustomAction, setLastCustomAction] = useState<
    string | undefined
  >();

  const [refineResult, setRefineResult] = useBoolean(true);

  function getTextToRefine() {
    if (refineResult && transformText.data?.text) {
      return transformText.data.text;
    }

    return editor.state.selection.empty
      ? editor.getText()
      : editor.extensionStorage.aiContent.lastSelectionText;
  }

  function resetRefining() {
    setVisible.off();
    transformText.reset();
  }

  function toggleRefining() {
    transformText.reset();
    setVisible.toggle();
  }

  function submit(value: TextTransformAction, customAction: string = '') {
    if (transformText.isLoading) return;

    transformText.mutate({
      source: getTextToRefine(),
      action: value,
      customAction,
    });

    setLastStyleUsed(value);
    setLastCustomAction(customAction);
  }

  function resubmit() {
    if (transformText.isLoading || !lastStyleUsed) return;

    transformText.mutate({
      source: getTextToRefine(),
      action: lastStyleUsed,
      customAction: lastCustomAction,
    });
  }

  function replaceContent(value: string) {
    if (editor.extensionStorage.aiContent.lastSelectionIsEmpty) {
      editor
        .chain()
        .setContent('')
        .insertContent(value, { parseOptions })
        .run();
    } else {
      editor.chain().insertContent(value, { parseOptions }).run();
    }

    resetRefining();
  }

  function insertContent(value: string) {
    editor
      .chain()
      .focus('end')
      .insertContent(`\n${value}`, { parseOptions })
      .run();
    resetRefining();
  }

  if (!refineTextSelectionVisible) return null;

  return (
    <div
      className={mergeTailwindClasses(
        'tw-relative tw-m-4 tw-flex tw-flex-col tw-gap-2 tw-rounded tw-border tw-border-dashed tw-border-transparent',
        clsx({
          'tw-border-purple-100': visible,
        }),
      )}
    >
      <div
        className={clsx('-tw-mt-3 tw-transition-transform', {
          'tw-translate-x-2': visible,
        })}
      >
        <Button
          iconBefore={<Icon icon={faSparkles} />}
          size="small"
          variant="ghost"
          className={mergeTailwindClasses(
            clsx(
              'tw-border tw-border-dashed tw-border-purple-100 !tw-bg-white hover:!tw-bg-purple-10',
            ),
          )}
          data-name="ai-content-text-selection-refine-text"
          onClick={() => toggleRefining()}
        >
          {t('refineWithAI')}
        </Button>
      </div>
      {visible && (
        <IconButton
          variant="ghost"
          size="small"
          className="tw-absolute tw-right-1 tw-top-1 tw-z-10"
          data-name="ai-content-text-selection-close-button"
          onClick={() => resetRefining()}
        >
          <Icon icon={faXmark} className="tw-text-grayscale-120" />
        </IconButton>
      )}
      {transformText.isLoading && (
        <LoadingGeneratedResults
          title={t('refiningYourText')}
          classNames={{
            img: 'tw-h-[5.5rem] tw-mb-3',
          }}
        />
      )}
      {transformText.data && !transformText.isLoading && visible && (
        <span
          className="tw-block tw-max-h-[280px] tw-flex-grow tw-overflow-y-auto tw-whitespace-pre-wrap tw-break-words tw-p-3 tw-text-md tw-text-grayscale-180"
          data-name="ai-content-text-selection-result-text"
        >
          {transformText.data.text}
        </span>
      )}
      {!!transformText.data?.text && (
        <Field.Group className="tw-px-6">
          <div className="tw-flex tw-items-center">
            <Toggle
              checked={refineResult}
              onChange={() => setRefineResult.toggle()}
              data-name="ai-content-text-selection-refine-result-toggle"
              size="small"
            />
            <Field.Label
              className="tw-ml-2 tw-flex tw-cursor-pointer tw-items-center"
              data-name="ai-content-text-selection-refine-result-label"
              onClick={() => setRefineResult.toggle()}
              required
            >
              {t('refineResult')}
              <Tooltip content={t('refineResultTooltip')}>
                <Icon icon={faCircleQuestion} marginLeft={4} size="sm" />
              </Tooltip>
            </Field.Label>
          </div>
        </Field.Group>
      )}
      {(visible || (transformText.data?.text && refineResult)) && (
        <div className="tw-grid tw-grid-cols-3 tw-gap-2 tw-p-3 tw-pt-0">
          <Button
            size="small"
            variant="ghost"
            data-name="ai-content-text-selection-refine-text-make-engaging"
            className="tw-justify-start"
            onClick={() => submit('makeEngaging')}
            iconBefore={<Icon icon={faBolt} />}
            disabled={transformText.isLoading}
          >
            {t('makeEngaging')}
          </Button>
          <Button
            size="small"
            variant="ghost"
            data-name="ai-content-text-selection-refine-text-add-emojis"
            className="tw-justify-start"
            onClick={() => submit('addEmojis')}
            iconBefore={<Icon icon={faFaceSmile} />}
            disabled={transformText.isLoading}
          >
            {t('addEmojis')}
          </Button>
          <Button
            size="small"
            variant="ghost"
            data-name="ai-content-text-selection-refine-text-fix"
            className="tw-justify-start"
            onClick={() => submit('fixGrammar')}
            iconBefore={<Icon icon={faSpellCheck} />}
            disabled={transformText.isLoading}
          >
            {t('fixGrammar')}
          </Button>
          <Button
            size="small"
            variant="ghost"
            data-name="ai-content-text-selection-refine-text-rewrite"
            className="tw-justify-start"
            onClick={() => submit('rewrite')}
            iconBefore={<Icon icon={faPenSwirl} />}
            disabled={transformText.isLoading}
          >
            {t('rewrite')}
          </Button>
          <Button
            size="small"
            variant="ghost"
            className="tw-justify-start"
            data-name="ai-content-text-selection-refine-text-expand"
            onClick={() => submit('expand')}
            iconBefore={<Icon icon={faIndent} />}
            disabled={transformText.isLoading}
          >
            {t('expand')}
          </Button>
          <Button
            size="small"
            variant="ghost"
            className="tw-justify-start"
            data-name="ai-content-text-selection-refine-text-shorten"
            onClick={() => submit('shorten')}
            iconBefore={<Icon icon={faIndent} className="tw-rotate-180 " />}
            disabled={transformText.isLoading}
          >
            {t('shorten')}
          </Button>
          <div className="tw-col-span-3">
            <TextEditorAiContentRefineTextSelectionInput
              onSubmit={(value) => submit('custom', value)}
              disabled={transformText.isLoading}
            />
          </div>
        </div>
      )}
      {!!transformText.data?.text && (
        <div className="tw-mb-3 tw-flex tw-justify-end tw-gap-2">
          <Button
            onClick={() => resubmit()}
            data-name="ai-content-text-selection-refine-text-retry"
            variant="plain"
            size="small"
            className="tw-mr-3"
          >
            <span className="text-sm">{t('retry')}</span>
          </Button>
          <Button
            onClick={() => insertContent(transformText.data?.text)}
            data-name="ai-content-text-selection-refine-text-insert"
            variant="plain"
            size="small"
            className="tw-mr-3"
          >
            <Icon icon={faArrowRightToArc} />
            <span className="text-sm">{t('insert')}</span>
          </Button>
          <Button
            onClick={() => replaceContent(transformText.data?.text)}
            data-name="ai-content-text-selection-refine-text-replace"
            size="small"
            className="tw-mr-3"
          >
            <Icon icon={faCheck} />
            <span className="text-sm">{t('replace')}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default TextEditorAiContentRefineTextSelection;

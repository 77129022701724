import React, { FC } from 'react';
import { BrandLogo, Icon } from '@kontentino/ui';
import getSocialMediaTypeForBrandLogo from 'app/utils/getSocialMediaTypeForBrandLogo';
import ProfileSelectorOptionActions from 'app/components/profileSelector/components/profileSelectorOption/ProfileSelectorOptionActions';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';

type Props = {
  option: {
    label: string;
    logoSrc?: string;
    socialMediaType?: number;
    isGlobal?: boolean;
  };
  onReconnectClick?: () => void;
};

const ProfileSelectorSubOption: FC<Props> = ({ option, onReconnectClick }) => (
  <div className="tw-flex tw-h-7 tw-select-none tw-items-center">
    <div className="tw-ml-3 tw-mr-2 tw-flex tw-items-center tw-gap-1">
      <BrandLogo
        src={option.logoSrc}
        name={option.label}
        size={24}
        socialMediaType={getSocialMediaTypeForBrandLogo(option.socialMediaType)}
        iconSize={12}
      />
      {option.isGlobal && (
        <Icon icon={faGlobe} className="tw-text-grayscale-100" size="md" />
      )}
    </div>

    <p className="tw-max-w-[140px] tw-truncate  tw-text-md tw-font-medium">
      {option.label}
    </p>
    {onReconnectClick && (
      <div className="tw-ml-auto">
        <ProfileSelectorOptionActions
          eventHandlers={{
            onReconnectClick,
          }}
        />
      </div>
    )}
  </div>
);

export default ProfileSelectorSubOption;

import { get, post } from 'api/client';
import { MentionableUser, UserAssignedToPage } from 'types/User';
import { PageData, PageDetail } from 'types/Page';
import { jsonToFormData } from 'utils/formData';
import { getExtendedTimeout } from 'utils/request';
import config from 'config';

type CreateParams = {
  name?: string;
  userName?: string;
  type: number;
  logo?: File | null;
};

type UpdatePageParams = {
  id: number;
  timezone?: string;
};

const PageApi = {
  async getAll(): Promise<ApiResponse<PageData[]>> {
    return get<ApiResponse<PageData[]>>('/pages', {
      baseURL: config.API_SERVICE_ENDPOINT,
      timeout: getExtendedTimeout(),
    });
  },
  async localPages(id: number): Promise<ApiResponse<PageData[]>> {
    return get(`/page/${id}/local-pages`, {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
  detail(id: number): Promise<PageDetail> {
    return get(`/page/${id}`, {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
  assignedUsers(ids: number[]): Promise<UserAssignedToPage[]> {
    return get('/pages/ajaxGetAssignedUsers', {
      params: {
        ids: JSON.stringify(ids),
      },
    });
  },
  create(params: CreateParams): Promise<{ id: number }> {
    const formData = new FormData();

    if (params.name) {
      formData.append('name', params.name);
    }

    if (params.userName) {
      formData.append('username', params.userName);
    }

    formData.append('type', params.type.toString());

    if (params.logo) {
      formData.append('medialibrary_files_logo[0]', params.logo);
    }

    return post('/pages/ajaxCreatePage', formData).then((data: any) => {
      if (typeof data?.id === 'string') {
        data.id = Number(data.id);
      }

      return data;
    });
  },
  update(params: UpdatePageParams) {
    return post('/pages/ajaxUpdatePage', jsonToFormData(params));
  },
  mentionableUsers(pageId: number): Promise<MentionableUser[]> {
    return get('/user/ajaxGetMentionableUsers', {
      params: { id: pageId },
      timeout: getExtendedTimeout(),
    });
  },
};

export default PageApi;

import { Avatar, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import CommentsListItemHeader from 'app/modules/posts/modules/post/components/postCommunication/comments/commentsList/CommentsListItemHeader';
import CommentsListItemFooter from 'app/modules/posts/modules/post/components/postCommunication/comments/commentsList/CommentsListItemFooter';
import PostCommunicationUserCard from '../../PostCommunicationUserCard';
import CommentsListItemReactionsList from './commentsListItem/CommentsListItemReactionsList';
import CommentsListItemActions from './commentsListItem/commentsListItemActions/CommentsListItemActions';
import {
  CommentActionHoverProvider,
  useCommentActionHover,
} from './providers/CommentActionHoverProvider';
import { ReactionSummaryProvider } from './providers/ReactionSummaryProvider';
import { usePostCommunicationContext } from '../../PostCommunicationProvider';
import { User } from 'types/User';
import CommentsListItemBubble from 'app/modules/posts/modules/post/components/postCommunication/comments/commentsList/CommentsListItemBubble';
import { Comment } from 'app/modules/comments/types';
import { CommentsConfig } from 'app/modules/comments/config';
import { PostCommunicationContent } from '../../types/postCommunicationContent';

type Props = {
  children: ReactNode;
  comment: Comment;
  isDemo?: boolean;
  hideAvatar?: boolean;
  hideHeader?: boolean;
  colorVariant: 'primary' | 'purple';
  currentUser: Pick<User, 'role'>;
  user?: User;
  showUserCard?: boolean;
};

const CommentsListItemBody: FC<Props> = ({
  children,
  comment,
  isDemo,
  hideAvatar,
  hideHeader,
  colorVariant,
  showUserCard = true,
  currentUser,
}) => {
  const { user: author } = comment;
  const { setIsHoveringOverParent, displayActions } = useCommentActionHover();
  const { disableCommunicationActions, activeContent } =
    usePostCommunicationContext();
  const isPinnedContent = activeContent === PostCommunicationContent.PINNED;

  const shouldShowEmojiPicker =
    (comment.reactions_summary.length > 0 ||
      (isPinnedContent && comment.reactions_summary.length === 0)) &&
    !disableCommunicationActions;

  return (
    <div
      className={clsx('tw-flex tw-flex-col', {
        'tw-justify-self-end': author.its_me,
      })}
      data-cy="post-comment"
      data-name="comments_comment"
      data-id={
        ['client_approval_request', 'internal_approval_request'].includes(
          comment.type,
        ) && !comment.meta
          ? CommentsConfig.ACTIVE_APPROVAL_REQUEST_DATA_ID
          : undefined
      }
      onMouseOver={() => setIsHoveringOverParent(true)}
      onMouseLeave={() => setIsHoveringOverParent(false)}
    >
      <div
        className={clsx('tw-flex tw-items-end tw-gap-2', {
          'tw-flex-row-reverse': author.its_me,
        })}
      >
        {!hideAvatar ? (
          <Tooltip
            arrow={false}
            content={
              showUserCard && (
                <PostCommunicationUserCard
                  user={{
                    avatarSrc: author.avatar?.src,
                    name: author.name,
                    isCurrent: author.its_me,
                    id: author.id,
                    role: author.role,
                    email: author.email,
                  }}
                />
              )
            }
            interactive={true}
            className={clsx('!tw-border-0 !tw-bg-transparent !tw-p-0', {
              'tw-cursor-pointer': showUserCard,
            })}
            placement="auto"
          >
            <Avatar
              src={author.avatar?.src}
              name={author.name}
              size={32}
              className={clsx({ 'hover:tw-cursor-pointer': showUserCard })}
            />
          </Tooltip>
        ) : (
          <div className="tw-h-8 tw-w-8" />
        )}
        <div className="tw-flex tw-flex-col">
          {!hideHeader && (
            <CommentsListItemHeader
              currentUser={currentUser}
              comment={comment}
              isDemo={isDemo}
              className="tw-mb-1 tw-mt-3 "
            />
          )}
          <div
            className={clsx('tw-flex tw-items-center tw-justify-end tw-gap-2', {
              'tw-flex-row-reverse': !author.its_me,
            })}
          >
            {' '}
            {!comment.deleted_at && (
              <div
                className={clsx('tw-flex tw-gap-x-2', {
                  'tw-opacity-0': !displayActions,
                  'tw-flex-row-reverse': !author.its_me,
                })}
              >
                {!disableCommunicationActions && (
                  <CommentsListItemActions comment={comment} />
                )}
              </div>
            )}
            <CommentsListItemBubble
              comment={comment}
              colorVariant={colorVariant}
            >
              {children}
            </CommentsListItemBubble>
          </div>
        </div>
      </div>
      {shouldShowEmojiPicker && !comment.deleted_at && (
        <div
          className={clsx('tw-flex tw-gap-x-2', {
            'tw-flex-row-reverse': author.its_me,
          })}
        >
          <div className="tw-h-8 tw-w-8" />
          <CommentsListItemReactionsList itsMe={author.its_me} />
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-gap-2">
        <CommentsListItemFooter comment={comment} />
      </div>
    </div>
  );
};

const CommentsListItem: FC<Props> = (props) => {
  const { comment } = props;

  return (
    <CommentActionHoverProvider>
      <ReactionSummaryProvider comment={comment}>
        <CommentsListItemBody {...props} />
      </ReactionSummaryProvider>
    </CommentActionHoverProvider>
  );
};

export default CommentsListItem;

import { STATUS } from '@kontentino/kontentino-constants/Posts';
import { ListPost, PostDetail } from 'types/PostDetail';

export const isLocalPostLocked = (post: {
  globalPost?: PostDetail['globalPost'];
  isGlobal?: PostDetail['isGlobal'];
}) => {
  return (
    post.globalPost?.status !== STATUS.APPROVED &&
    !post.isGlobal &&
    post.globalPost
  );
};

export const isLocalPostPreviewLocked = (post: {
  globalPostStatus: PostDetail['globalPostStatus'];
}) => {
  return (
    post.globalPostStatus != null && post.globalPostStatus !== STATUS.APPROVED
  );
};

export const isGlobalPostDistributed = (post: PostDetail) => {
  return post.isGlobal && post.status === STATUS.APPROVED;
};

export const isPostGlobal = (post: PostDetail | ListPost) => {
  return post.isGlobal || post.globalPostStatus !== null;
};

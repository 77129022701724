import React, { FC } from 'react';
import style from 'app/modules/insights/modules/postsPerformance/components/pageStatistics/statisticCard/Card.module.scss';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import ColorUtils from 'app/utils/color';
import { colors, Icon, Tooltip } from '@kontentino/ui';
import { formatMetricValue } from 'app/modules/insights/utils/formatMetricValue';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import i18n from 'i18n';
import clsx from 'clsx';
import { formatNumberValue } from 'app/modules/insights/modules/pagePerformance/utils/format';

type Props = {
  className?: string;
  title: string;
  info?: string;
  value: number | string | null;
  color?: string;
  icon?: IconDefinition;
  onClick?(): void;
  isHighlighted?: boolean;
  disabled?: boolean;
  name: string;
  withExactValue?: boolean;
};

const StatisticCard: FC<Props> = ({
  className,
  title,
  value,
  color,
  icon,
  onClick,
  isHighlighted,
  info,
  disabled = false,
  name,
  withExactValue,
}) => {
  return (
    <Tooltip content={disabled ? i18n.disabledMetricMessage : undefined}>
      <div
        className={clsx(
          style.card,
          disabled && style.disabled,
          isHighlighted && style.highlighted,
          className,
        )}
        onClick={disabled ? undefined : onClick}
        data-name={`insights_statistics-card-${name}`}
        tabIndex={0}
      >
        <div className={style.textSide}>
          <span className={style.title}>
            {info && (
              <Tooltip content={info}>
                <span className={style.hint}>
                  <Icon icon={faCircleInfo} withoutBoundary size="inherit" />
                </span>
              </Tooltip>
            )}
            {title}
          </span>
          <Tooltip content={value ? formatNumberValue(value) : undefined}>
            <span className={style.value}>
              {formatMetricValue(name, value)}{' '}
              {withExactValue && value ? (
                <span className="tw-text-sm tw-font-regular tw-text-grayscale-90">
                  ({formatNumberValue(value)})
                </span>
              ) : null}
            </span>
          </Tooltip>
        </div>
        {color && icon && (
          <div
            style={{
              backgroundColor: ColorUtils.hexToRGBA(
                disabled ? colors.gray20 : color,
                disabled ? 1 : 0.1,
              ),
            }}
            className={clsx(
              'tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-[50%]',
              style.icon,
            )}
          >
            <Icon
              size="xl"
              icon={icon}
              style={{ color: disabled ? colors.gray60 : color }}
            />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default StatisticCard;

import React, { FC } from 'react';
import AIGenerateButton from 'app/modules/aiContent/components/AIGenerateButton';

type Props = {
  hasResults?: boolean;
  isLoading?: boolean;
  onSubmit(): void;
};

const TextEditorAiContentPopupGenerateFooter: FC<Props> = ({
  hasResults,
  isLoading,
  onSubmit,
}) => {
  return (
    <div className="tw-flex tw-justify-end tw-border-t tw-border-grayscale-20 tw-p-4 [@media(max-height:1000px)]:tw-py-2">
      <AIGenerateButton
        onClick={onSubmit}
        isLoading={isLoading}
        regenerate={hasResults}
      />
    </div>
  );
};

export default TextEditorAiContentPopupGenerateFooter;
